html {
  body {
    footer {
      &.page-footer {
        margin-top: 0;
        height: $footer-height;
        background: $footer-bg-color;
        font-size: $footer-font-size;
        color: $footer-color;

        a {
          color: $footer-color;

          &:hover {
            text-decoration: none;
          }
        }

        main {
          @extend .container;
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;

          div {
            &.logo {
              font-size: 30px;

              ul.social {
                @extend .list-unstyled;
                @extend .list-inline;
                margin-bottom: 0;
                margin-top: $_unit * 2;
                width: 100%;
                text-align: center;

                li {
                  display: inline-block;
                  &:not(:last-child) {
                    margin-right: $grid-gutter-width / 2;
                  }
                  a {
                    display: block;
                    background-repeat: no-repeat;
                    background-size: contain;
                    background-position: center center;
                    opacity: .75;
                    width: 24px;
                    height: 24px;
                    &:hover {
                      opacity: 1;
                    }
                  }
                  &[data-network="vk"] {
                    a {
                      background-image: url("/assets/img/icons/sni-vk.svg");
                    }
                  }
                  &[data-network="instagram"] {
                    a {
                      background-image: url("/assets/img/icons/sni-instagram.svg");
                    }
                  }
                }
              }
            }

            &.contacts {
              text-align: right;

              a {
                display: block;

                &:not(:last-child) {
                  margin-bottom: $_unit * 2;
                }

                &.phone {
                  font-size: 22px;
                  font-weight: 500;
                  span {
                    display: block;
                  }
                  small {
                    display: none;
                  }
                }

                &.email {
                  font-size: 16px;
                  font-weight: 500;
                }
              }
            }

            p.schedule {
              text-align: center;
              margin-top: $_unit;
            }
          }
        }
      }
    }
  }
}
