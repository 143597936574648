@mixin special-font {
  font-family: $footer-font;
  font-size: $footer-font-size;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $color-text-bright
}

@mixin bg-overlay-image($img-with-url) {
  background-image: $img-with-url;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}


@mixin intro-button {
  background: $color-intro-btn-bg;
  border: 0;
  border-radius: 6px;
  transition: 250ms;
  width: 100%;
  max-width: 280px;
  height: 64px;
  margin-left: 16px;
  transform: translateY(30px);
  text-align: center;
  @include special-font;
  color: $color-intro-btn-text;
  font-size: 18px;

  &:hover {
    background: $color-btn2-hover;
  }

  &:active, &:focus {
    background: $color-btn2-pressed;
    outline: none;
  }
}

@mixin second-button {
  color: $color-text-bright;
  background: $color-text-accent;
  margin: auto auto;
  transform: none;
  width: 100%;
  max-width: 280px;
  height: 64px;
  line-height: 64px;
  display: block;
  transition: 250ms;

  &:hover {
    text-decoration: none;
    background: $color-btn1-hover
  }

  &:active, &:focus {
    background: $color-btn1-pressed
  }
}

@mixin optional-at-root($sel) {
  @at-root #{if(not &, $sel, selector-append(&, $sel))} {
    @content;
  }
}

@mixin placeholder {
  @include optional-at-root('::-webkit-input-placeholder') {
    @content;
  }

  @include optional-at-root(':-moz-placeholder') {
    @content;
  }

  @include optional-at-root('::-moz-placeholder') {
    @content;
  }

  @include optional-at-root(':-ms-input-placeholder') {
    @content;
  }
}

@mixin gradient-border($color1, $color2, $border-width, $direction) {
  border: none;
  background-repeat: no-repeat;
  background-image: linear-gradient(#{$direction}, $color1 0%, $color2 100%), linear-gradient(#{$direction}, $color1 0%, $color2 100%);
  @if $direction == 'to right' or $direction == 'to left' {
    @if $direction == 'to right' {
      border-left: $border-width solid $color1;
      border-right: $border-width solid $color2;
    } @else {
      border-left: $border-width solid $color2;
      border-right: $border-width solid $color1;
    }
    background-position: 0 0, 0 100%;
    background-size: 100% $border-width;
  }

  @if $direction == 'to top' or $direction == 'to bottom' {
    @if $direction == 'to top' {
      border-top: $border-width solid $color2;
      border-bottom: $border-width solid $color1;
    } @else {
      border-top: $border-width solid $color1;
      border-bottom: $border-width solid $color2;
    }
    background-position: 0 0, 100% 0;
    background-size: $border-width 100%;
  }
}


@mixin mobile-menu-button($bg) {
  width: 32px;
  height: 23px;
  text-indent: -10000px;
  background: $bg;
  background-repeat: no-repeat;
  background-size: initial;
  background-position: center center;
  border: 0;
  transition: 500ms;
  &:active, &:focus {
    outline: none;
    box-shadow: none;
  }
}
