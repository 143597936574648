@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation : landscape) {
  html {
    body {
      max-width: 1024px;

      .container {
        padding-left: 0;
        padding-right: 0;
      }

      section {
        &.view-contact-us {
          .page-intro-wrapper {
            ul.view-contact-us-contacts {
              li {
                div {
                  margin-left: 60px;
                  transform: none;
                }
              }
            }
          }
        }

        &.page-content {
          div {
            &.container.pt-0 {
              .row {
                .pt-e {
                  &:last-child {
                    padding-left: 30px;
                  }
                }
                div.pr-e {
                  padding-right: 30px;
                }
              }
            }
          }
        }

      }


    }
  }
}
