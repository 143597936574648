@media only screen and (min-width: 200px) and (max-width: 769px) {
  html {
    body {
      /*margin-bottom: $footer-phone-height;*/

      div.container {
        padding-left: 23px;
        padding-right: 23px;
      }

      header {
        &.page-header {
          a {
            &.header-logo {
              img {
                width: 75px;
                height: 24px;
              }
            }
            &.join-now {
              height: 32px;
              width: auto;
              padding: 0 15px;
              margin: 0;
              transform: translateY(4px);
            }
          }

          section.navigation {
            display: none;
          }
        }

        &.ultra-header {
          background: transparent;
          height: auto;
          font-size: 14px;

          a {
            font-size: 14px;
          }

          div.row {
            padding: 36px 0 0 0;
          }

          .my-auto {
            margin-top: 0 !important;
            margin-bottom: 0 !important;
          }

          ul.footer-contacts {
            text-align: left !important;

            li {
              margin-bottom: 22px;

              &:last-child {
                margin-bottom: 0;
              }

              i.ico {
                margin-right: 11px;
              }
            }
          }
        }

      }

      footer {
        &.page-footer {
          background: $gradient-phone-footer;
          height: $footer-phone-height;
          font-size: 14px;

          a {
            font-size: 14px;
          }

          div.row {
            padding: 36px 0 0 0;
          }

          .my-auto {
            margin-top: 0 !important;
            margin-bottom: 0 !important;
          }

          ul.footer-contacts {
            text-align: left !important;

            li {
              margin-bottom: 22px;

              &:last-child {
                margin-bottom: 0;
              }

              i.ico {
                margin-right: 11px;
              }
            }
          }
        }
      }

      section {
        &.page-pre-footer {
          div.container {
            padding: 63px 0;

            a.to-top-of-page {
              transform: translate(-50px, -90px);
            }
          }
        }

        &.page-intro {
          .learn-more {
            display: none;
          }

          .page-intro-wrapper {
            padding-top: 20px;
            height: auto;
            min-height: auto;
          }

          .page-intro-content {
            padding-top: 64px;

            h1 {
              font-size: 42px;
              word-break: break-word;
            }

            p {
              font-size: 16px;
              line-height: 1.75;
            }


            .join-now {
              transform: none;
              margin: 54px auto;
              display: block;
              position: relative;
            }

            .intro-links {
              display: flex;
              flex-direction: column;
              li {
                display: flex;
              }
            }

            a.contact-us {
              transform: none;
              margin: auto;
              margin-bottom: 42px !important;
              margin-top: 30px !important;
            }

          }

          &.view-contact-us, &.view-about-us {
            background-image: none;

            .page-intro-wrapper {
              min-height: calc(100vh - var($footer-phone-height));
              padding-bottom: 60px;
              background: $gradient-phone;

              .page-intro-content {

                .view-about-us-contacts {
                  text-align: center;
                }

                .view-contact-us-contacts {
                  margin-left: 0;
                  margin-right: 0;

                  li {
                    text-align: center;
                    border-right: 0;
                    padding: 50px 0;
                    border-bottom: 1px solid $color-text-bright;

                    div {
                      transform: none;
                      margin-left: 0;
                    }

                    &:first-child {
                      padding-top: 0;
                    }

                    &:last-child {
                      padding-bottom: 0;
                      border-bottom: none;
                    }
                  }
                }


                form {
                  h3 {
                    font-size: 32px;
                  }
                }

              }

            }
          }
        }

        &.page-content {
          margin: 62px 0;
          margin-top: 0;

          .content-block {
            padding-top: 42px;
            padding-bottom: 42px;

            &#price {
              width: 100vw;
              padding-left: 0;
              padding-right: 0;
            }


            .col-half {
              width: 100%;
              box-sizing: border-box;

              &:first-child {
                padding-bottom: 32px;
              }
            }

            &.chipped {
              padding-top: 0;
              padding-bottom: 0;

              .col-half {
                &:first-child {
                  padding-top: 44px;
                  padding-bottom: 44px;
                  padding-right: 0;
                }

                &:last-child {
                  padding-top: 44px;
                  padding-bottom: 44px;
                  padding-left: 0;
                  background: transparent;

                  &:before {
                    display: block;
                    content: '';
                    text-indent: -100000px;
                    height: 1px;
                    background: $gradient-default;
                    margin-top: -44px;
                    margin-bottom: 44px;
                    width: 100vw;
                    position: absolute;
                    left: 0;
                  }
                }
              }
            }

          }


          .vertical-gradient-borders {
            &:before, &:after {
            }
          }

          .photo-gallery-block {
            height: auto;

            .content-block {
              .carousel {
                height: auto;
              }
            }
          }

          .gradient-accented-block {
            &.publishers {
              //margin-top: -88px;
              //margin-bottom: 42px;
            }
          }

          .content-block {
            //padding: 42px 23px;


            &.pt-0 {
              .pt-e {
                padding: 0;

                .target-tip {
                  position: initial;
                  bottom: initial;
                  margin-top: 20px;
                }

                &.decor-right {
                  padding: 0;
                  border: none;
                  background: transparent;
                }
              }
            }

            .row {
              margin-right: 0;
              margin-left: 0;

              .col-lg-6 {
                text-align: left !important;

                &:after {
                  display: block;
                  content: '';
                  text-indent: -100000px;
                  width: 100vw;
                  height: 1px;
                  background: $gradient-default;
                  margin-left: -23px;
                  margin-top: 43px;
                  margin-bottom: 43px;
                }

                &.mobile-no-border {
                  &:after {
                    display: none;
                  }

                  padding: 0;

                  img {
                    margin-bottom: 32px;
                  }
                }

                &.mobile-with-border {
                  &:after {
                    display: block !important;
                    content: '';
                    text-indent: -100000px;
                    width: 100vw;
                    height: 1px;
                    background: $gradient-default;
                    margin-left: -23px;
                    margin-top: 43px;
                    margin-bottom: 43px;
                  }

                  padding: 0;

                  img {
                    margin-bottom: 32px;
                  }
                }
              }

              .target-tip {
                position: unset;
                bottom: auto;
              }

              &.payments-row {
                .col-lg-6 {
                  img {
                    display: block;
                    margin: auto auto;
                  }
                }
              }
            }

            &:last-child {
              .row {
                .col-lg-6:last-child {
                  &:after {
                    display: none;
                  }
                }
              }
            }

          }

          &.view-publishers {
            .content-block {
              //padding: 0 23px;
            }
          }

        }
      }


      .modal.show {
        .modal-dialog {
          width: auto;
          max-width: calc(100vw - 46px);

          .modal-content {
            width: auto;
          }
        }
      }

      .carousel-item {
        &.active {
          display: flex;
        }

        justify-content: center;
        flex-direction: column;
        align-items: center;
        height: 100%;
      }


      .ems-faq {
        background-size: 24% !important;
      }

      section.page-content.view-group-classes {
        div.col-12.my-auto.text-center.p-5 {
          h4 {
            font-size: $font-size-base * 2;
          }
        }
      }

      section.page-content.view-body-correction {
        .content-block {
          .col-half {
            h4 {
              font-size: $font-size-base * 3;
            }
          }
        }
      }

    }
  }
}
