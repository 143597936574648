$_unit: 8px;

$safrane-beige: #f4eed7;
$safrane-brown: #9F8C46;

$color-text-bright: #fff;
$color-text-dark: #000;
/*
$color-text-accent: #ff367a;
$color-text-accent-2: #cf526e;
$color-text-accent-3: #cf526e;
*/
$color-text-accent: $safrane-brown;
$color-text-accent-2: darken($safrane-brown, 15%);
$color-text-accent-3: darken($safrane-brown, 25%);
$color-gradient-end: #0047c8;
$color-header-btn-hover: #5480c2;
$color-intro-btn-bg: $color-text-accent-2;
$color-intro-btn-text: $color-text-dark;
$color-accent-btn-bg: $color-text-accent;
$color-accent-btn-text: $color-text-bright;

$color-btn1-hover: #111111;
$color-btn1-pressed: #787C82;

$color-btn2-hover: #fff;
$color-btn2-pressed: rgba(255, 255, 255, .44);
$color-btn3: rgba(255, 255, 255, .35);

$gradient-default: linear-gradient(135deg, #787C82 0%, #000 52%, #787C82 100%);
$gradient-phone: linear-gradient(0deg, #787C82, #000);
$gradient-phone-footer: $gradient-default;
$gradient-transparent: linear-gradient(135deg, transparentize(#787C82, .07) 0%, transparentize(#000, .05) 52%, transparentize(#787C82, .05) 100%);

$font-stack: 'Roboto', sans-serif;

$footer-font: $font-stack;
$footer-height: 128px;
$footer-phone-height: 214px;
$footer-color: $color-text-bright;
$footer-bg-color: #111111;
$footer-bg-image: $gradient-default;
$footer-font-size: 16px;

$bg-intro-depilation: url('/assets/img/intro/depilation.jpg');
