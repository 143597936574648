@media only screen and (min-width: 1px) and (max-width: 991px) {
  html {
    max-width: 100vw;

    body {

      #modalLetsMeet {
        .modal-footer {
          a.phone {
            color: #fff;
            span {
              display: block;
              border-bottom: none;
              padding-bottom: 4px;
              &:before {
                display: inline-block;
                content: "";
                width: 16px;
                height: 16px;
                margin-right: 6px;
                transform: rotate(25deg);
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center center;
                background-image: url("/assets/img/icons/phone-call.svg");
              }
            }

            small {
              display: block;
              font-size: 11px;
              opacity: .75;
              text-align: center;
              padding-top: 4px;
            }
          }
        }
      }

      section {
        &.page-intro {
          position: relative;

          div.page-intro-video {
            height: auto;
            min-height: 100%;

            video {
              height: 100%;
            }
          }

          .page-intro-wrapper {
            padding-bottom: 30px;
            box-sizing: content-box;

            .page-intro-content {
              .join-now {
                transform: none;
                display: block;
              }

              a.join-now {
                display: flex;
                align-items: center;
                justify-content: center;
              }

              section.intro-buttons {
                display: block;

                &.collg8 {
                  flex: auto;
                  width: 100%;
                  max-width: 100%;
                }
              }

            }
          }
        }
      }

      .page-content {
        .action-discount {
          b {
            font-size: $font-size-base * 3;
          }
        }

        ul.list-ways {
          &:before {
            display: block;
            content: '';
            width: 18px;
            height: 19px;
            position: absolute;
            border-bottom: 1px solid $color-text-accent;
            border-left: 1px solid $color-text-accent;
            margin-left: -10px;
            margin-top: -47px;
            transform: rotate(135deg);
            transform-origin: right;
            background: #fff;
          }

          li {
            &:before {
              display: none;
            }
          }
        }

        .mobile-flex-reverse {
          display: flex;
          flex-direction: column-reverse;
        }

        .mmb-5 {
          margin-bottom: $_unit * 5;
        }

        .col-half {
          /*h4 {
            text-align: center;
          }
          img.mb-5 {
            margin-left: auto;
            margin-right: auto;
            display: block;
          }*/
        }

        div.table-responsive {
          &:after {
            display: block;
            content: '';
            width: 51px;
            height: 32px;
            background: transparent;
            background-image: url("/assets/img/content-icons/icons/noun_Horizontal Scroll_110445.svg");
            background-repeat: no-repeat;
            background-position: center center;
            background-size: contain;
            position: sticky;
            left: calc(50% - 25px);
          }

          table.table {
            td {
              min-width: 100px;
            }
          }
        }

        .gradient-accented-block {
          div.table-responsive {
            &:after {
              background-image: url("/assets/img/content-icons/icons/noun_Horizontal Scroll_110445-2.svg");
            }
          }
        }

      }

      footer {
        &.page-footer {
          height: auto;
          margin-top: 0;
          padding: $_unit * 5 0;

          main {
            flex-direction: column;

            div {
              margin-bottom: $_unit * 3;
              text-align: center;

              &:last-child {
                margin-bottom: 0;
              }

              &.contacts {
                a.phone {
                  span {
                    display: block;
                    border-bottom: 1px dotted;
                    padding-bottom: 4px;
                    &:before {
                      display: inline-block;
                      content: "";
                      width: 16px;
                      height: 16px;
                      margin-right: 6px;
                      transform: rotate(25deg);
                      background-repeat: no-repeat;
                      background-size: contain;
                      background-position: center center;
                      background-image: url("/assets/img/icons/phone-call.svg");
                    }
                  }

                  small {
                    display: block;
                    font-size: 11px;
                    opacity: .75;
                    text-align: center;
                    padding-top: 4px;
                  }
                }
              }
            }
          }

          div.bg-dark {
            margin-bottom: -40px;
            margin-top: 40px;
          }
        }
      }

      header {
        &.ultra-header {
          height: auto;
          margin-top: 0;
          padding: $_unit * 5 0;
          display: none;

          main {
            flex-direction: column;
            align-items: center;
            padding-top: 0;

            div {
              margin-bottom: $_unit * 3;
              text-align: center;

              &:last-child {
                margin-bottom: 0;
              }

              &.logo, &.contacts {
                a.phone {
                  span {
                    display: block;
                    border-bottom: 1px dotted;
                    padding-bottom: 4px;
                    &:before {
                      display: inline-block;
                      content: "";
                      width: 16px;
                      height: 16px;
                      margin-right: 6px;
                      transform: rotate(25deg);
                      background-repeat: no-repeat;
                      background-size: contain;
                      background-position: center center;
                      background-image: url("/assets/img/icons/phone-call.svg");
                    }
                  }

                  small {
                    display: block;
                    font-size: 11px;
                    opacity: .75;
                    text-align: center;
                    padding-top: 4px;
                  }
                }
              }
            }
          }
        }

        &.page-header {
          position: fixed;
          left: 0;
          top: 0;
          width: 100vw;
          height: 60px;
          padding: 10px 23px;
          background: #000000DC;
          z-index: 65535;

          section.navigation {
            padding-top: 64px;
            header {
              position: fixed;
              left: 0;
              top: 0;
              width: 100vw;
              height: 60px;
              padding: 10px 23px;
              background: #000000DC;
              z-index: 65535;
            }
          }
        }
      }

    }
  }
}


@media only screen and (min-width: 200px) and (max-width: 585px) {
  html {
    body {
      header {
        .mobile-menu-header-container {
          padding: 0;
        }
      }
    }
  }
}
