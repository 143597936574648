@mixin payment_icon($width, $x, $y) {
  width: $width;
  height: 56px;
  background-size: cover;
  background-position: $x $y;
  background-image: url('/img/icons/pub-payments.png');
}

i.ico {
  display: inline-block;
  vertical-align: middle;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 34px;
  height: 34px;
  object-fit: contain;

  &.ico-small {
    width: 16px;
    height: 16px;
  }

  &.ico-skype-small {
    background-image: url('/img/icons/skype-small.svg');
  }

  &.ico-email-small {
    background-image: url('/img/icons/email-small.svg');
  }

  &.ico-email {
      background-image: url('/img/icons/email.svg');
    }
  &.ico-skype {
    background-image: url('/img/icons/skype.svg');
  }
  &.ico-linkedin {
    background-image: url('/img/icons/linkedin.svg');
  }

  &.ico-paypal {
    @include payment_icon(134px, 0, 0);
  }

  &.ico-paxum {
    @include payment_icon(132px, -179px, 0);
  }

  &.ico-payoneer {
    @include payment_icon(128px, -359px, 0);
  }

  &.ico-webmoney {
    @include payment_icon(135px, -533px, 0);
  }

  &.ico-wire {
    @include payment_icon(91px, -712px, 0);
  }

}
