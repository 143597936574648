section {
  &.page-content {
    padding-top: 0;
    padding-bottom: 0;

    .action-discount {
      text-align: center;
      color: $color-text-bright;
      background: $color-accent-btn-bg;
      @extend .p-5;
      @include special-font();
      font-size: $font-size-base * 2;
      font-weight: 500;

      b {
        font-weight: bold;
        font-size: $font-size-base * 2.25;
      }
    }

    .content-block {
      padding-top: 105px;
      padding-bottom: 105px;

      &.review-content-block {
        padding-bottom: 30px;
      }

      .col-half {
        width: 50%;
        box-sizing: border-box;
      }

      h3 {
        font-weight: bold;
        @extend .mb-4;
      }

      &.chipped {
        padding-top: 0;
        padding-bottom: 0;

        .col-half {
          &:first-child {
            padding-top: 52px;
            padding-bottom: 52px;
            padding-right: 4rem;
          }

          &:last-child {
            padding-top: 52px;
            padding-bottom: 52px;
            padding-left: 4rem;

            @include gradient-border($color-text-accent-2, $color-text-accent, 1px, 'to bottom');
            border-bottom: 0;
            border-right: 0;
            border-top: 0;
            background-position: 0;
          }
        }
      }

      table.table-price {
        caption {
          color: $color-text-dark;
          text-align: center;
          caption-side: top;
        }

        tr {
          color: $color-text-dark;
          text-align: left;

          td {
            vertical-align: middle;
            border-color: $color-text-accent;
          }
        }

        thead {
          tr {
            th {
              border-color: $color-text-accent-3;
            }
          }
        }
      }

      .features-list {
        @extend .list-unstyled;

        li {
          &:before {
            display: inline-block;
            content: '';
            width: 48px;
            height: 48px;
            position: absolute;
            margin-left: -90px;
            background-position: center center;
            background-size: contain;
            background-repeat: no-repeat;
          }

          padding-left: 90px;
          margin-bottom: 60px;

          &:last-child {
            margin-bottom: 0;
          }

          h4 {
            font-size: $font-size-base * 2.25;
            color: $color-text-accent;
          }

          p {
            font-size: $font-size-base* 1.25;

            &:last-child {
              padding-bottom: 0;
            }
          }

          &.ico-beauty {
            &:before {
              background-image: url("/assets/img/content-icons/icons/mirror1600.png");
            }
          }

          &.ico-comfort {
            &:before {
              background-image: url("/assets/img/content-icons/icons/HouseholdArmchairicon.png");
            }
          }

          &.ico-ems {
            &:before {
              background-image: url("/assets/img/content-icons/icons/FranchiseIcon150x150.png");
            }
          }

          &.ico-clean {
            &:before {
              background-image: url("/assets/img/content-icons/icons/lotus512.png");
            }
          }

          &.ico-personal {
            &:before {
              background-image: url("/assets/img/content-icons/icons/w512h5121377939745185082manpeoplestreamlineuser.png");
            }
          }

          &.ico-bar {
            &:before {
              background-image: url("/assets/img/content-icons/icons/bebidarefrescante_2.png");
            }
          }

          &.ico-noun {
            &:before {
              background-image: url("/assets/img/content-icons/icons/noun_219630.png");
            }
          }

          &.ico-tea {
            &:before {
              background-image: url("/assets/img/content-icons/icons/tea.png");
            }
          }

          &.ico-icon4 {
            &:before {
              background-image: url("/assets/img/content-icons/icons/icon4.png");
            }
          }

          &.ico-fork {
            &:before {
              background-image: url("/assets/img/content-icons/icons/fork.png");
            }
          }

          &.ico-cup {
            &:before {
              background-image: url("/assets/img/content-icons/icons/cup.png");
            }
          }

          &.ico-torso {
            &:before {
              background-image: url("/assets/img/content-icons/icons/torsoicon61415.png");
            }
          }

        }
      }

      .row {
        margin-left: 0;
        margin-right: 0;
      }

      h1, h4 {
        @include special-font;
        font-size: 36px;
        font-weight: bold;
        color: $color-text-dark;
        line-height: normal;
        margin-bottom: 24px;

        u {
          text-decoration: none;
          color: $color-text-accent
        }
      }

      p {
        @include special-font;
        line-height: 1.67;
        font-size: 18px;
        color: $color-text-dark;
      }

      ul {
        padding-left: 20px;

        li {
          @include special-font;
          line-height: 1.67;
          font-size: 18px;
          color: $color-text-dark;
        }
      }

      .target-tip {
        @include special-font;
        font-size: 13px;
        color: $color-text-accent;
        text-align: left;
        text-transform: uppercase;
        letter-spacing: 7.429px;
        font-weight: 400;
        padding-left: 31px;
        background-size: 17px 17px;
        background-position: left center;
        background-repeat: no-repeat;
        background-image: url("/assets/img/icons/target-tip.svg");
      }

      &.pt-0 {
        div.pt-e {
          padding-top: 105px;
          padding-bottom: 105px;

          &.decor-right {
            padding-left: 80px;
          }

          .target-tip {
            position: absolute;
            bottom: 75px;
          }
        }
      }

      div {
        &.pr-e {
          padding-right: 60px;
        }

        &.decor-right {
          @include gradient-border($color-text-accent, $color-gradient-end, 1px, 'to bottom');
          border-bottom: 0;
          border-right: 0;
          border-top: 0;
          background-position: 0;
        }
      }

    }

    .photo-gallery-block {
      background: $black;
      height: 720px;
      max-height: 720px;

      .content-block {
        padding: 0;

        .carousel {
          height: 720px;
          max-height: 720px;

          .carousel-inner {
            @extend .h-100;

            .carousel-item {
              &.active {
                display: flex;
                align-items: center;
                justify-content: center;
              }
              height: 100%;
              float: inherit;
              position: relative;
            }

          }
        }
      }
    }

    .gradient-accented-block {
      background: $gradient-default;

      .content-block {
        h4, p {
          color: $color-text-bright;
        }

        .target-tip {
          color: $color-text-bright;
          background-image: url("/assets/img/icons/target-tip-white.svg");
          display: inline-block;
        }
      }
    }

    .vertical-gradient-borders {
      &:before, &:after {
        display: block;
        content: '';
        text-indent: -100000px;
        width: 100%;
        height: 1px;
        background: $gradient-default;
      }
    }

  }


  &.page-intro {
    position: relative;

    div {
      &.page-intro-video {
        width: 100%;
        height: 100%;
        overflow: hidden;
        position: absolute;

        video {
          position: absolute;
          left: 0;
          width: 100%;
          min-width: 100%;
          height: 100%;
          min-height: 100vh;
          object-fit: cover;
        }

        div.page-intro-video__overlay {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          background: $gradient-transparent;
          opacity: .88;
        }

        &.depilation {
          @include bg-overlay-image($bg-intro-depilation);
        }

      }

      &.page-intro-wrapper {
        padding-top: 48px;
        height: auto;
        min-height: 100vh;
        background: $gradient-transparent;

        &.h-auto {
          padding-bottom: 48px;
        }

        &.short {
          min-height: auto;
          height: auto;
        }
      }

      .view-index-counters {
        li {
          @include special-font;

          &:last-child {
            margin-bottom: 0;
          }

          b {
            display: block;
            font-weight: bold;
            font-size: 82px;

            span {
              @include special-font;
              font-size: 48px;
            }
          }

          small {
            display: block;
            opacity: 0.6;
            font-size: 18px;
            transform: translate(3px, -15px);
          }

          a {
            color: $color-text-bright;
            font-weight: bold;

            &:hover {
              color: $color-text-bright;
            }
          }
        }
      }

      button.join-now {

        color: $color-text-bright;

        &:hover {
          color: $color-text-dark
        }
      }

      a.learn-more {
        font-size: 14px;
        letter-spacing: 8.05px;
        color: $color-text-bright;
        text-transform: uppercase;
        width: 279px;
        background: url("/assets/img/learn-more.svg");
        background-repeat: no-repeat;
        text-align: right;
        transform: rotate(270deg) translateY(-29px);
        transform-origin: right;
        position: absolute;
        top: calc(100vh - 418px);
        right: 5px;
        cursor: pointer;
        display: block;
        transition: 250ms;
        opacity: 1;
        z-index: 65000;

        &:hover {
          text-decoration: none;
          color: $color-text-accent-2;
          background-image: url("/assets/img/learn-more-hover.svg");
        }

        &:active, &:focus {
          outline: none;
        }
      }


      &.page-intro-content {
        padding-top: 97px;

        h1 {
          padding: 0;
          margin: 0;
          margin-bottom: 30px;
          @include special-font;
          font-size: 62px;
          font-weight: 700;

          u {
            text-decoration: none;
            color: $color-text-accent;
          }
        }

        p {
          @include special-font;
          font-size: 20px;
          line-height: 1.7;

          &.service-promo {
            @extend .text-center;
            font-size: 24px;
            line-height: 1.7;

            u {
              font-weight: bold;
              font-size: 36px;
              text-decoration: none;
              color: $color-text-accent;
            }
          }
        }

        ul {
          &.intro-links {
            @extend .list-unstyled;
            @extend .list-inline;

            li {
              @extend .list-inline-item;
              padding-bottom: $_unit * 5;

              a {
                color: $white;
                border-bottom: 1px dashed transparentize($white, .2);
                padding-bottom: 2px;
                font-size: $font-size-base * 1.75;

                &:hover {
                  text-decoration: none;
                  border-bottom-color: $white;
                }
              }
            }
          }
        }

        section.intro-buttons {
          display: flex;
          width: 100%;
          justify-content: space-between;
          &.collg8 {
            flex: 0 0 66.66667%;
            width: 66.66667%;
            max-width: 66.66667%;
          }
        }

      }
    }

    .join-now {
      @include intro-button;
      margin-bottom: 60px;
    }

    a.join-now {
      color: $color-text-bright;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        color: $color-text-dark;
        text-decoration: none;
      }
    }
  }

  .nice-button {
    @include intro-button;
    @include second-button;
    @extend .mt-5;
    max-width: 320px;
  }

  &.page-pre-footer {
    &:before {
      display: block;
      content: '';
      text-indent: -100000px;
      width: 100%;
      height: 1px;
      background: $gradient-default;
    }

    a.to-top-of-page {
      position: absolute;
      right: 0;
      transform: translateY(7px);

      i {
        transition: 250ms;
        display: block;
        content: '';
        text-indent: -100000px;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: $color-btn1-hover;
        background-image: url("/assets/img/arrow-up.svg");
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 100%;
      }

      &:hover {
        i {
          background-color: $color-btn1-pressed;
        }
      }

      &:active {
        i {
          background-color: lighten($color-btn1-pressed, 10%);
        }
      }
    }

    div.container {
      padding: 107px 0;
    }

    .pre-footer-button {
      @include intro-button;
      @include second-button;
      padding: 16px;
      height: auto;
      line-height: 1.25;
    }
  }
}

.review-row {
  width: 100%;
  display: flex;
  flex-direction: row;

  img {
    margin-right: $grid-gutter-width;
  }

  article {
    font-family: Roboto, sans-serif;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    line-height: 1.67;
    font-size: 18px;
    color: #000;
    width: calc(100% - 94px);
  }

  div.author {
    text-align: right;
    font-style: italic;
    flex-basis: 100%;
    margin-top: 2rem;
  }
}

ul {
  &.view-index-services {
    li {
      @include special-font;
      margin-bottom: 24px;

      &:last-child {
        margin-bottom: 0;
      }

      b {
        display: inline-block;
        font-weight: bold;
      }

      small {
        display: block;
        opacity: 0.6;
        font-size: 18px;
        transform: translate(3px, -15px);
      }

      a {
        color: $color-text-bright;
        font-weight: bold;

        &:hover {
          color: $color-text-bright;
        }
      }
    }
  }
}

body {
  section.view-thanks {
    .page-intro-wrapper {
      height: auto;
      min-height: auto;
      padding-bottom: 48px;
    }
  }
}

.btn-online-record {

}