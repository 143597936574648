html {
  body {
    header {
      &.page-header {
        display: flex;
        justify-content: space-between;
        width: 100%;

        a.header-logo {
          span {
            @include special-font;
            font-size: 27px;
            font-weight: 700;
            text-transform: uppercase;
            margin-bottom: 0;
          }

          &:hover {
            text-decoration: none;
          }
        }

        section.navigation {
          flex: 2;
          justify-content: space-evenly;
          display: flex;
        }

        nav {
          &.main-menu {
            flex: 2;
            justify-content: space-evenly;

            a {
              transition: 250ms;
              opacity: 0.7;
              @include special-font;

              &:last-child {
                margin-right: 0;
              }

              &:active, &:focus {
                outline: none;
              }

              &.active {
                opacity: 1;
                color: $color-text-accent-2;
                font-weight: bold;
                cursor: default;
              }

              &:hover {
                text-decoration: none;
                opacity: 1;
              }
            }
          }

          &.sign-menu {
            button {
              @include special-font;
              width: auto;
              margin-right: 24px;
              background: transparent;
              border-radius: 6px;
              border: 1px solid $color-text-bright;
              height: 35px;
              line-height: 1;
              vertical-align: middle;
              transition: 250ms;

              &:last-child {
                margin-right: 0;
              }

              &:hover {
                background: $color-btn2-hover;
                color: $color-text-dark
              }

              &:active, &:focus {
                outline: none;
                background: $color-btn2-pressed;
                color: $color-text-dark;
                border-color: $color-btn2-pressed;
              }

              &#btnOnlineOrder {
                background: $color-btn3;
                &:hover {
                  background: $color-btn2-hover;
                  color: $color-text-dark
                }

                &:active, &:focus {
                  outline: none;
                  background: $color-btn2-pressed;
                  color: $color-text-dark;
                  border-color: $color-btn2-pressed;
                }
              }

            }
          }
        }
      }
    }
  }
}