html {
  position: relative;
  min-height: 100%;
  -webkit-font-smoothing: antialiased;

  body {
    font-family: $font-stack;
    margin-bottom: -1 * $footer-height;
    @include special-font;
    color: $color-text-dark;
    background: $safrane-beige;
  }
}
